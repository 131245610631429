import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Resources',
    url: '/resources',
    iconComponent: { name: 'cil-notes' },
  },
  {
    name: 'Files',
    url: '/files',
    iconComponent: { name: 'cil-file' },
  },
  {
    name: 'Definitions',
    url: '/definitions',
    iconComponent: { name: 'cil-spreadsheet' },
  },
  {
    name: 'MCMIS',
    url: '/mcmis',
    iconComponent: { name: 'cil-car-alt' },
  }
];
