<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <button toggle="visible" cHeaderToggler [cSidebarToggle]="sidebarId" class="ps-1">
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>

    <c-header-nav class="d-none d-lg-flex me-auto">
      <ng-container *ngTemplateOutlet="searchDropdown"></ng-container>
      <!-- <c-nav-item>
        <a cNavLink routerLink="/dashboard" routerLinkActive="active">
          Dashboard
        </a>
      </c-nav-item>
      <c-nav-item>
        <a cNavLink routerLink="/users" routerLinkActive="active">Users</a>
      </c-nav-item>
      <c-nav-item>
        <a cNavLink routerLink="/settings" routerLinkActive="active">
          Settings
        </a>
      </c-nav-item> -->
    </c-header-nav>

    <c-header-nav class="d-none d-lg-flex">
      <!-- <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilBell" size="lg"></svg>
        </a>
      </c-nav-item>
      <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilList" size="lg"></svg>
        </a>
      </c-nav-item>
      <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilEnvelopeOpen" size="lg"></svg>
        </a>
      </c-nav-item> -->
    </c-header-nav>
    
    <c-header-nav class="ms-3">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>

  </c-container>
  <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <!-- <c-breadcrumb-router class="ms-2"></c-breadcrumb-router> -->
    <!-- Breadcrumb -->
    <nav *ngIf="id != null || fileId != null"
      style="--cui-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/"><i class="fa fa-home"></i></a></li>
        <li *ngIf="id != null && sectionId == null" class="breadcrumb-item active" aria-current="page">Resource</li>
        <li *ngIf="id != null && sectionId != null" class="breadcrumb-item active" aria-current="page"><a
            routerLink="/resources/{{id}}">Resource</a></li>
        <li *ngFor="let section of breadcrumbs; let i = index" class="breadcrumb-item active" aria-current="page"><a
            routerLink="/resources/{{id}}/sections/{{section.id}}">{{section.title}}</a></li>
        <li *ngIf="id != null && sectionId != null" class="breadcrumb-item active" aria-current="page">Section</li>
        <li *ngIf="fileId != null" class="breadcrumb-item active" aria-current="page"><a
          routerLink="/files/{{id}}">Files</a></li>
        <li *ngIf="fileId != null" class="breadcrumb-item active" aria-current="page">Files</li>
      </ol>
    </nav>
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #searchDropdown>
  <c-dropdown alignment="start" variant="nav-item" style="min-width: 100%;">
    <input type="text" id="search-text" name="input1-group1" placeholder="Search..." class="form-control"
      autocomplete="off" cDropdownToggle [(ngModel)]="searchText" (ngModelChange)="search(searchText)">
    <ul cDropdownMenu class="pt-0 pr-5 w-auto">
      <!--loading-->
      <div *ngIf="searchViewState == viewStates.loading" class="text-center p-4">
        <div class="spinner-border text-ts" role="status">
          <!-- <span class="sr-only">Loading...</span> -->
        </div>
      </div>
      <div *ngIf="response != null && searchViewState == viewStates.content" class="mb-2 p-2"
            style="max-height: 250px; overflow-y: scroll;">
            <!--empty-->
            <div class="text-center" *ngIf="response.resources.length == 0 && response.sections.length == 0">
              <div><i class="fa fa-search mb-2 mt-4"></i></div>
              <div>No Results</div>
            </div>
            <!--search resources-->
            <div *ngIf="response.resources.length > 0">
              <b class="text-ts">Resources</b>
              <table class="table table-responsive">
                <tr *ngFor="let resource of response.resources; let i = index">
                  <td class="p-2">
                    <img *ngIf="resource.coverImageUrl != null" class="rounded" src="{{resource.coverImageUrl}}"
                      style="object-fit: cover; height: 20px; width: 20px;" />
                    <img *ngIf="resource.coverImageUrl == null" class="rounded"
                      src="https://img.icons8.com/color/200/000000/open-book--v1.png"
                      style="object-fit: contain; height: 20px; width: 20px;" />
                  </td>
                  <td class="p-2">
                    <a routerLink="/resources/{{resource.id}}">
                      <small><b>{{resource.title}}</b></small>
                    </a>
                  </td>
                </tr>
              </table>
            </div>

            <!--search sections-->
            <div *ngIf="response.sections.length > 0">
              <b class="text-ts">Sections</b>
              <table class="table table-responsive">
                <tr *ngFor="let section of response.sections; let i = index">
                  <td class="text-center">
                    <div>
                      <small>
                        <b>
                          <span *ngIf="section.referenceType == null">Section</span>
                          <span *ngIf="section.referenceType != null">{{section.referenceType.name}}</span>
                          <span *ngIf="section.referenceId != null">{{section.referenceId}}</span>
                        </b>
                      </small>

                    </div>
                    <div><small *ngIf="section.sectionRange != null">{{section.sectionRange}}</small></div>
                  </td>
                  <td>
                    <a routerLink="/resources/{{section.resource?.id}}/sections/{{section.id}}">
                      <small><b>{{section.title}}</b></small>
                    </a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
    </ul>
  </c-dropdown>
</ng-template>

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button cButton color="" [caret]="false" cDropdownToggle class="py-0">
      <c-avatar cTextColor="primary" shape="rounded-1" size="md" src="https://img.icons8.com/color/48/test-account.png"
        status="success">
      </c-avatar>
    </button>
    <ul cDropdownMenu class="pt-0 pr-5 w-auto">


      <li>
        <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
          dropdownToggle (click)="false">
          <i class="fa fa-user-circle-o fa-lg"></i>
        </a>
      </li>
      <li>
        <a class="dropdown-item" href="{{logoutUrl()}}"><i class="fa fa-lock"></i> Logout</a>
      </li>

      <!--account-->
      <!-- <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
        dropdownToggle (click)="false">
        <i class="fa fa-user-circle-o fa-lg"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>Account</strong></div>
        <a class="dropdown-item" href="{{logoutUrl()}}"><i class="fa fa-lock"></i> Logout</a>
      </div>
    </li> -->
    </ul>
  </c-dropdown>
</ng-template>