import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DefaultLayoutComponent } from './containers';
import { AuthGuard } from '@auth0/auth0-angular';
import { ResourcesComponent } from './views/resources/resources/resources.component';
import { FilesComponent } from './views/files/files/files.component';
import { FileComponent } from './views/files/file/file.component';
import { AddFileComponent } from './views/files/add-file/add-file.component';
import { ResourceComponent } from './views/resources/resource/resource.component';
import { SectionComponent } from './views/sections/section/section.component';
import { McmisSearchComponent } from './views/mcmis/search/search.component';
import { DefinitionComponent } from './views/definitions/definition/definition.component';
import { DefinitionsComponent } from './views/definitions/definitions/definitions.component';
// import { ResourceComponent } from './views/resources/resource/resource.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'resources',
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Resources'
    },
    children: [
      {
        path: 'definitions',
        loadChildren: () => import('./views/definitions/definitions.module').then(m => m.DefinitionsModule),
        component: DefinitionsComponent
      },
      {
        path: 'definitions/:id',
        loadChildren: () => import('./views/definitions/definitions.module').then(m => m.DefinitionsModule),
        component: DefinitionComponent
      },
      {
        path: 'resources',
        loadChildren: () => import('./views/resources/resources.module').then(m => m.ResourcesModule),
        component: ResourcesComponent
      },
      {
        path: 'resources/:id',
        loadChildren: () => import('./views/resources/resources.module').then(m => m.ResourcesModule),
        component: ResourceComponent
      },
      {
        path: 'files',
        loadChildren: () => import('./views/files/files.module').then(m => m.FilesModule),
        component: FilesComponent
      },
      {
        path: 'files/:fileId',
        loadChildren: () => import('./views/files/files.module').then(m => m.FilesModule),
        component: FileComponent
      },
      {
        path: 'add-file',
        loadChildren: () => import('./views/files/files.module').then(m => m.FilesModule),
        component: AddFileComponent
      },
      {
        path: 'resources/:id/sections/:sectionId',
        loadChildren: () => import('./views/sections/sections.module').then(m => m.SectionsModule),
        component: SectionComponent
      },
      {
        path: 'mcmis',
        loadChildren: () => import('./views/mcmis/mcmis.module').then(m => m.McmisModule),
        component: McmisSearchComponent
      },
    ]
  },
  {path: '**', redirectTo: 'resources'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
      // relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
