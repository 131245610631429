import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { ViewState } from 'src/app/models/app';
import { SearchResponseDto, SectionBaseDto } from 'src/app/models/dtos';
import { environment } from 'src/environments/environment';
import { navItems } from '../_nav'
import { cilSearch } from '@coreui/icons';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent {
  constructor(private classToggler: ClassToggleService, public auth: AuthService, @Inject(DOCUMENT) private doc: Document, private http: HttpClient, private route: ActivatedRoute, private router: Router) {
    super();
  }
  icons = { cilSearch };

  @Input() sidebarId: string = "sidebar";

  public newMessages = new Array(4)
  public newTasks = new Array(5)
  public newNotifications = new Array(5)

  

  public sidebarMinimized = false;
  public navItems = navItems;
  public breadcrumbs: SectionBaseDto[] = [];
  searchText: string = "";
  response?: SearchResponseDto;

  //breadcrumbs
  public id?: string
  public sectionId?: string
  public fileId?: string

  //view states
  viewStates = ViewState;
  searchViewState = ViewState.content;

  ngOnInit(): void {
    //gather initial route params at page load
    if(this.route.snapshot.firstChild == null) { return }
    
    this.id = this.route.snapshot.firstChild.params['id'];
    this.sectionId = this.route.snapshot.firstChild.params['sectionId'];
    this.fileId = this.route.snapshot.firstChild.params['fileId'];
    this.getBreadcrumbs(this.sectionId);

    //subscribe to route updates
    this.router.events
      .subscribe(val => {
        if (val instanceof NavigationEnd) {
          console.log(this.router.url);
          this.id = this.route?.snapshot?.firstChild?.params['id'];
          this.sectionId = this.route?.snapshot?.firstChild?.params['sectionId'];
          this.fileId = this.route?.snapshot?.firstChild?.params['fileId'];

          //get breadcrumbs
          this.getBreadcrumbs(this.sectionId);
        }
      });
  }

  //api
  search(searchText?: string) {
    // this.viewState = ViewState.loading;
    if(searchText == null || searchText == '') { return; }
    
    this.searchViewState = ViewState.loading;
    this.http
      .get<SearchResponseDto>(`${environment.services_legacy_management}/v1/search?q=${searchText}`)
      .subscribe(response => {
        this.response = response;
        this.searchViewState = ViewState.content;
      });
  }

  logout(): void {
    //sign out formally with routing
    this.auth.logout({ 
      logoutParams: {
        returnTo: this.doc.location.origin,
      },
      // client_id: environment.auth0_client_id 
    });
  }

  logoutUrl() : string {
    return `https://${environment.auth0_domain}/v2/logout?client_id=${environment.auth0_client_id}&returnTo=`+encodeURIComponent(`${this.doc.location.origin}`);
  }

  toggleMinimize(e: any) {
    this.sidebarMinimized = e;
  }

  getBreadcrumbs(id?: string) {
    //on a root/resource page. Early return
    if (id == null) { 
      this.breadcrumbs = [];
      return; 
    }

    //otherwise get breadcrumbs
    this.http
      .get<SectionBaseDto[]>(`${environment.services_legacy_management}/v1/sections/${id}/breadcrumbs`)
      .subscribe(result => {
        this.breadcrumbs = result;
      });
  }
}
